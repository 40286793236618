import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { BaseTitle, LgTitle } from '../elements/Titles';
import { SmParagraph, XsParagraph } from '../elements/Paragraphs';

const News = () => (
  <Layout>
    <SEO title="News" />
    <Container>
      <Row marginBottom="144">
        <Column>
          <LgTitle>News</LgTitle>
        </Column>
        <Column>
        <SmParagraph marginBottom="12">July 10, 2023</SmParagraph>
        <BaseTitle>
          <a
              href="https://www.cbc.ca/radio/podcastplaylist/how-do-other-people-handle-their-money-podcasts-about-personal-finance-ft-maya-lau-1.6900276"
              target="_blank"
              rel="noopener noreferrer"
          >
            How do other people handle their money? Podcasts about personal finance (ft. Maya Lau)
          </a>
        </BaseTitle>
        <XsParagraph>CBC Radio</XsParagraph>
        <SmParagraph marginBottom="12">January 27, 2023</SmParagraph>
        <BaseTitle>
          <a
              href="https://www.uottawa.ca/current-students/career-experiential-learning/coop/news-all/navigating-first-co-op-work-term-communication-students-experience"
              target="_blank"
              rel="noopener noreferrer"
          >
            Navigating the first CO-OP work term: A communication student’s experience
          </a>
        </BaseTitle>
        <XsParagraph>University of Ottawa</XsParagraph>
        <SmParagraph marginBottom="12">March 17, 2022</SmParagraph>
          <BaseTitle>
            <a
              href="https://business.twitter.com/en/success-stories/moneywise-wise-publishing.html"
              target="_blank"
              rel="noopener noreferrer"
            >
            How Wise Publishing brand MoneyWise improved cost-per-site-visit and drove traffic to their article
            </a>
          </BaseTitle>
          <XsParagraph>Twitter Business</XsParagraph>
        <SmParagraph marginBottom="12">Sep 01, 2021</SmParagraph>
          <BaseTitle>
            <a
              href="https://www.newswire.com/news/wise-publishing-secures-5m-investment-from-strategic-investor-21487845"
              target="_blank"
              rel="noopener noreferrer"
            >
            Wise Publishing Secures $5M Investment From Strategic Investor
            </a>
          </BaseTitle>
          <XsParagraph>Newswire</XsParagraph>
          <SmParagraph marginBottom="12">May 25, 2021</SmParagraph>
          <BaseTitle>
            <a
              href="https://www.postmedia.com/2021/05/25/postmedia-and-wise-publishing-announce-investment-strategic-partnership/"
              target="_blank"
              rel="noopener noreferrer"
            >
            Postmedia and Wise Publishing Announce Investment, Strategic Partnership
            </a>
          </BaseTitle>
          <XsParagraph>Postmedia</XsParagraph>

          <SmParagraph marginBottom="12">July 29, 2020</SmParagraph>
          <BaseTitle>
            <a
              href="https://www.newswire.com/news/wise-publishing-inc-buys-half-banked-personal-finance-site-for-21184862"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wise Publishing, Inc. Buys Half Banked, Personal Finance Site for Millennials
            </a>
          </BaseTitle>
          <XsParagraph>Newswire</XsParagraph>
          <SmParagraph marginBottom="12">January 22, 2020</SmParagraph>
          <BaseTitle>
            <a
              href="https://www.taboola.com/resources/case-studies/moneywise"
              target="_blank"
              rel="noopener noreferrer"
            >
              After Experiencing Success as an Advertiser, MoneyWise.com Turns
              to Taboola to Increase Revenue
            </a>
          </BaseTitle>
          <XsParagraph>Taboola</XsParagraph>
          <SmParagraph marginBottom="12">July 23, 2019</SmParagraph>
          <BaseTitle>
            <a
              href="https://adexchanger.com/publishers/wise-publishing-gets-wise-to-latency-reduction/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wise Publishing Gets Wise To Latency Reduction
            </a>
          </BaseTitle>
          <XsParagraph>AdExchanger</XsParagraph>
          <SmParagraph marginBottom="12">June 3, 2019</SmParagraph>
          <BaseTitle>
            <a
              href="https://ca.finance.yahoo.com/news/wise-publishing-inc-secures-financing-195914537.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wise Publishing Secures Financing from Schedule 1 Bank
            </a>
          </BaseTitle>
          <XsParagraph>Yahoo! Finance</XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default News;
